.root {
  overflow: hidden;
}

#headlessui-portal-root {
  @apply text-neutral-900 dark:text-neutral-200 text-base;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hiddenScrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hiddenScrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.z-max {
  z-index: 999999999;
}

//
.nc-will-change-transform {
  will-change: transform;
}
.nc-will-change-top {
  will-change: top;
}
//

.nc-SectionClientSay .glide__bullet--active {
  @apply bg-neutral-700;
}
.nc-GallerySlider .glide__bullet--active {
  @apply bg-white w-2 h-2;
}

//
.nc-card-title {
  @apply transition-colors  hover:text-primary-900 dark:hover:text-primary-300 duration-75;
}

//
.nc-PostCardCommentBtn,
.nc-PostCardLikeAction,
.nc-CommentCardLikeReply > button {
  &:hover {
    span {
      color: inherit;
    }
  }
}

//
.nc-box-has-hover {
  @apply bg-white border border-neutral-200 border-opacity-70 hover:bg-neutral-50 hover:shadow-none hover:border-transparent transition-shadow;
}

.nc-dark-box-bg-has-hover {
  @apply dark:bg-neutral-800 dark:bg-opacity-30  dark:border dark:border-neutral-700;
}

.nc-dark-box-bg {
  @apply dark:bg-neutral-800 dark:bg-opacity-30  dark:border dark:border-neutral-700 dark:shadow-none;
}

//
.nc-origin-100 {
  transform-origin: 100% 50% 0px;
}
.nc-origin-50 {
  transform-origin: 50% 50% 0px;
}

// HERO
.nc-hero-field-padding {
  @apply py-3 px-4 md:py-4 md:px-7 xl:py-6 xl:px-8;
}

.nc-hero-field-padding--small {
  @apply py-3 px-4 md:py-3 md:px-7 xl:px-8;
}

.nc-hero-field-focused {
  @apply dark:bg-white/5;
  box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px,
    rgba(17, 17, 26, 0.05) 0px 8px 32px;
}

.ListingDetailPage {
  .nc-hero-field-focused {
    @apply shadow-2xl;
  }
  &.nc-ListingExperiencesDetailPage {
    .ExperiencesDateSingleInput .nc-hero-field-focused {
      @apply rounded-r-none;
    }
    .listingExperiencesDetailPage__GuestsInput .nc-hero-field-focused {
      @apply rounded-l-none;
    }
  }

  .DateRangePickerInput_clearDates,
  .SingleDatePickerInput_clearDate {
    @apply rounded-full bg-neutral-200/90 dark:bg-white/10;
    svg {
      @apply w-2 fill-neutral-700 dark:fill-neutral-300;
    }
  }
}

.nc-ListingStayDetailPage__guestsInput {
  .nc-hero-field-focused {
    @apply rounded-t-none;
  }
}
.nc-ListingStayDetailPage__stayDatesRangeInput {
  .nc-hero-field-focused {
    @apply rounded-b-none;
  }
}

.nc-hero-field-focused--2 {
  @apply rounded-none dark:bg-white/5;
  box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px,
    rgba(17, 17, 26, 0.05) 0px 8px 32px;
}

.nc-flex-1 {
  flex: 1 0 0%;
}

.nc-flex-1\.5 {
  flex: 1.5 0 0%;
}
.nc-flex-1\.5-auto {
  flex: 1.5 0 auto;
}

.nc-flex-2 {
  flex: 2 0 0%;
}

.nc-flex-2-auto {
  flex: 2 0 auto;
}

.nc-flex-2\.5 {
  flex: 2.5 0 0%;
}

@screen lg {
  .lg\:nc-flex-1\.5 {
    flex: 1.5 0 0%;
  }
  .lg\:nc-flex-1\.5-auto {
    flex: 1.5 0 auto;
  }

  .lg\:nc-flex-2 {
    flex: 2 0 0%;
  }

  .lg\:nc-flex-2-auto {
    flex: 2 0 auto;
  }

  .lg\:nc-flex-2\.5 {
    flex: 2.5 0 0%;
  }
}

.nc-icon-field {
  @apply w-5 h-5 lg:w-7 lg:h-7;
}
.nc-icon-field-2 {
  @apply w-5 h-5 lg:w-8 lg:h-8;
}
//
button.gm-control-active.gm-fullscreen-control {
  @apply rounded-none #{!important};
}

//
.nc-FiveStartIconForRate {
  svg:hover ~ svg {
    @apply text-neutral-300;
  }
}

// LISTING PAGE DETAIL
.listingSection__wrap {
  @apply w-full flex flex-col sm:rounded-2xl border-b sm:border-t sm:border-l sm:border-r border-neutral-200 dark:border-neutral-700 space-y-6 sm:space-y-8 pb-10 px-0 sm:p-4 xl:p-8;
}

// LISTING PAGE DETAIL
.listingSectionSidebar__wrap {
  @apply w-full flex flex-col rounded-2xl border-b border-t border-l border-r border-neutral-200 dark:border-neutral-700 space-y-6 xl:space-y-7 pb-10 p-2 sm:p-4 xl:px-8 xl:py-6;
}

//
input[type="time"]::-webkit-calendar-picker-indicator {
  @apply dark:bg-neutral-500 rounded-md;
}

[type="button"] {
  -webkit-appearance: none !important;
}

//
.rc-slider-track {
  @apply bg-primary-400;
}
.rc-slider-handle {
  @apply border-primary-300;
}

//
.nc-PropertyCardH {
  .nc-BtnLikeIcon--liked {
    @apply text-red-500 dark:text-red-500;
  }
}

//
.ncSectionLogos {
  img {
    @apply w-full;
    max-width: 200px;
  }
}

//
.modalPhotos-single-gallery {
  .glide__bullet--active {
    @apply block;
  }
}

.menuIsNew_lv1 {
  > a::before {
    content: "New!";
    position: absolute;
    top: -2px;
    right: 0;
    display: inline-block;
    padding: 0 5px;
    line-height: 15px;
    border-radius: 4px;
    background-color: #f35656;
    color: #fff;
    font-size: 10px;
    font-weight: normal;
  }
}
.menuIsNew {
  > a::after {
    content: "New!";
    display: inline-block;
    padding: 0 5px;
    line-height: 15px;
    border-radius: 4px;
    background-color: #f35656;
    color: #fff;
    font-size: 10px;
    margin-left: 6px;
  }
}

.FooterNav--hide {
  @apply translate-y-full;
}

@keyframes myblur {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.nc-custom-shadow-1 {
  box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px,
    rgba(17, 17, 26, 0.05) 0px 8px 32px;
}

// -- //
.text-wrap {
  text-wrap: balance;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.no-mobile-br {
  & br {
    @apply hidden lg:block;
  }
}

.Typewriter__wrapper {
  position: relative;
  z-index: 1;
}

/* hero */
.background-hero {
  background-image: url("data:image/svg+xml,%0A%3Csvg width='548' height='548' viewBox='0 0 548 548' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M274 548C122.673 548 0 425.327 0 274C0 122.673 122.673 0 274 0C425.327 0 548 122.673 548 274C548 425.327 425.327 548 274 548ZM13.2252 274.148C13.2252 418.051 129.58 534.767 273.336 535.373V12.9384C129.58 13.5447 13.2252 130.252 13.2252 274.156' fill='%23F2F2F2'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: left -110px bottom -325px;
}

@media only screen and (max-width: 1023px) {
  .background-hero {
    background-image: unset;
  }
}

/* before signatures */
.signature-manifesto {
  @apply before:absolute before:w-[100px] before:h-[48px] before:bg-kirmizi before:left-2/4 before:rounded-b-full before:translate-x-[calc(50%_-_100%)] before:top-0;
}

.signature-left {
  // @apply before:absolute before:bg-white before:w-[calc(50%-15px)] before:h-[calc(100%-30px)] before:rounded-l-full before:top-[15px] before:left-[15px];
  @apply before:absolute before:bg-white before:w-[calc(50%-2%)] before:h-[calc(100%-4.5%)] before:rounded-l-full before:top-[2.4%] before:left-[2%];
}

/* manifesto */
.section-manifesto {
  & p:last-child {
    display: inline;
  }
}

.readMore {
  @apply relative inline-block text-lg lg:text-2xl text-white font-semibold select-none hover:cursor-pointer;

  &:before {
    content: "";
    width: calc(100% - 20px);
    height: 1.5px;
    position: absolute;
    background: #fff;
    bottom: 2.5px;
    transform: translateX(-50%);
    left: 50%;
  }
}

/* head */
.head-signature {
  @apply before:lg:w-[52px] before:lg:h-[103px] before:bg-kirmizi before:rounded-r-full before:mt-2 before:self-start before:flex-shrink-0 before:content-none before:lg:content-[''];

  & .head-text {
    @apply before:w-[33px] before:h-[64px] before:bg-kirmizi before:rounded-r-full before:self-start before:flex-shrink-0 before:block before:lg:content-none;
  }

  &.text-white {
    @apply before:bg-white;

    & .head-text {
      @apply before:bg-white;
    }
  }
}

/* expertise */
/*.expertise-bg {
  @apply bg-[#C1272D] before:bg-expertise-bg before:absolute before:w-full before:h-full before:opacity-30;
}*/
// .auto-grid {
//   display: grid;
//   --grid-col: var(--grid-col-number) !important;
//   grid-auto-flow: column;
//   grid-template-columns: repeat(2, 1fr);
//   grid-template-rows: repeat(var(--grid-col), 1fr);
// }

/* values */
.values-container {
  & .splide__list {
    @apply gap-[50px];
  }
}

.values-box {
  & .signature-values {
    @apply before:absolute before:bg-white;

    & > .desc {
      @apply flex text-lg font-normal absolute items-center p-5 text-center justify-center;
    }

    &.left {
      & > .desc {
        @apply w-1/2 h-full pl-[1.88rem];
      }

      @apply before:h-[calc(100%_-_16px)] before:w-[calc(50%_-_10px)] before:bg-white before:rounded-tl-full before:rounded-bl-full before:left-2 before:top-2;
    }
    &.right {
      & > .desc {
        @apply w-1/2 h-full right-0 pr-[1.88rem];
      }

      @apply before:h-[calc(100%_-_16px)] before:w-[calc(50%_-_10px)] before:bg-white before:rounded-tr-full before:rounded-br-full before:right-2 before:top-2;
    }
    &.top {
      @apply flex justify-center;

      & > .desc {
        @apply h-1/2 w-1/2 top-0 pb-0;
      }

      @apply before:absolute before:w-[calc(100%_-_16px)] before:h-[calc(50%_-_10px)] before:bg-white before:rounded-tl-full before:rounded-tr-full before:left-2 before:top-2;
    }

    &.bottom {
      @apply flex justify-center;

      & > .desc {
        @apply h-1/2 w-1/2 bottom-0 pt-0;
      }

      @apply before:absolute before:w-[calc(100%_-_16px)] before:h-[calc(50%_-_10px)] before:bg-white before:rounded-bl-full before:rounded-br-full before:left-2 before:bottom-2;
    }
  }
}

/* hr  */
.signature-hr {
  @apply before:absolute before:bg-white before:w-[calc(50%-10px)] before:h-[calc(100%-20px)] before:rounded-l-full before:top-[10px] before:left-[10px];
}

.section-hr {
  & .content {
    & h3,
    & h4,
    & h5,
    & h6 {
      @apply text-[28px] lg:text-[42px] leading-[40px] lg:leading-[60px] font-medium mb-2;
    }

    & p {
      @apply text-base lg:text-2xl mb-8 lg:mb-12 last:mb-0;

      & a {
        @apply font-medium underline hover:text-kirmizi transition;
      }
    }
  }
}

/* header */
#menu-anchor {
  opacity: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 10;
  cursor: pointer;
}

.menu {
  transition: all 0.12s;
  position: relative;
  width: 45px;
  height: 24px;
  border-radius: 100%;
  cursor: pointer;

  &_part {
    width: 45px;
    height: 2px;
    position: absolute;
    background: #000;
    right: 0;
    margin: auto;
    left: 0;
    border-radius: 0px;
    transition: all 0.12s cubic-bezier(0.5, 0, 0.2, 1.4);
    width: 18;
    top: calc(50% - ((3px)) + -2px);
    &:nth-of-type(1) {
      top: calc(50% - ((10px)) + -2px);
      width: 45px;
    }

    &:nth-of-type(2) {
      top: calc(50% + ((7px)) + 2px);
      width: 45px;
    }
  }
}

input#menu-anchor:checked + label .menu {
  transform: scale(1.17) rotate(0deg);
}

input#menu-anchor:checked + label .menu .menu_part:nth-of-type(1) {
  transform: rotate(45deg);
  top: calc(50% - (1px)) !important;
  width: 45px !important;
}

input#menu-anchor:checked + label .menu .menu_part:nth-of-type(2) {
  transform: rotate(-45deg);
  top: calc(50% - (1px)) !important;
  width: 45px !important;
}

.background-mobile-hero {
  background-image: url("data:image/svg+xml,%0A%3Csvg width='548' height='548' viewBox='0 0 548 548' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M274 548C122.673 548 0 425.327 0 274C0 122.673 122.673 0 274 0C425.327 0 548 122.673 548 274C548 425.327 425.327 548 274 548ZM13.2252 274.148C13.2252 418.051 129.58 534.767 273.336 535.373V12.9384C129.58 13.5447 13.2252 130.252 13.2252 274.156' fill='%23F2F2F2'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: left -170px bottom -280px;
}

@media only screen and (max-width: 767px) {
  .header-control.active {
    transform: translateY(-98px);

    & .mobileMenuBar {
      @apply hidden;
    }
  }

  .gdpr-control {
    transform: translateX(-100%) !important;
  }
}

@media only screen and (max-width: 639px) {
  .hero-right {
    @apply min-h-[250px] min-w-[250px] h-[calc(100vh_-_20%)] w-[calc(100vh_-_110%)];
  }
}

/* privacy-policy */
.privacy-policy {
  & h6 {
    @apply text-lg font-semibold mb-1 mt-5;
  }
}

/* costumer-portfolio */
.section-costumer-portfolio {
  & .splide__list {
    & li {
      @apply grid justify-center;
    }
  }
}
