html body {
  @apply font-body antialiased;
}

h1,
h2,
h3,
h4 {
  @apply font-display;
}

:root {
  --font-display: "futura-pt";
  --font-body: "futura-pt";
  -webkit-locale: "tr-TR";
}
